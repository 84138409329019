<template>
	<modal ref="modalAgregarCuenta" titulo="Crear cuenta de pasarela de pago - Mercado pago" tamano="modal-lg" no-aceptar
		adicional="Crear" @adicional="agregar">
		<ValidationObserver ref="form" class="w-100">
			<div class="row mx-0 justify-center">
				<div class="col-6">
					<ValidationProvider v-slot="{ errors }" rules="required" name="nombre">
						<p class="f-12 text-general pl-3 f-500 mt-3">Nombre de la cuenta</p>
						<el-input v-model="model.nombre" size="small" placeholder="Nombre" class="w-100" />
						<span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
					</ValidationProvider>
					<ValidationProvider v-slot="{ errors }" rules="required" name="public key">
						<p class="f-12 text-general pl-3 f-500 mt-3">Public Key</p>
						<el-input v-model="model.publickKey" size="small" placeholder="public key" class="w-100" />
						<span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
					</ValidationProvider>
					<ValidationProvider v-slot="{ errors }" rules="required" name="access token">
						<p class="f-12 text-general pl-3 f-500 mt-3">Access token</p>
						<el-input v-model="model.accessToken" size="small" placeholder="access token" class="w-100" />
						<span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
					</ValidationProvider>
					<ValidationProvider v-slot="{ errors }" rules="required" name="descripcion">
						<p class="f-12 text-general pl-3 f-500 mt-4">Descripción</p>
						<el-input v-model="model.descripcion" placeholder="Descripción de la Cuenta" type="textarea"
							:rows="5" class="w-100" />
						<span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
					</ValidationProvider>
				</div>
				<div class="col-6">
					<ValidationProvider v-slot="{ errors }" rules="required|max:30" name="código de la empresa">
						<p class="f-12 text-general pl-3 f-500 mt-3">Código de la empresa </p>
						<el-input v-model="model.clientId" size="small" placeholder="código de la empresa" class="w-100" />
						<span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
					</ValidationProvider>
					<ValidationProvider v-slot="{ errors }" rules="required|max:120" name="client secret">
						<p class="f-12 text-general pl-3 f-500 mt-3">Client secret</p>
						<el-input v-model="model.clientSecret" size="small" placeholder="client secret" class="w-100" />
						<span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
					</ValidationProvider>
					<ValidationProvider v-slot="{ errors }" rules="required" name="moneda">
						<p class="f-12 text-general f-500 pl-3 mt-3">Moneda</p>
						<el-select v-model="model.idm_moneda" size="small" placeholder="seleccionar" class="w-100">
							<el-option v-for="item in selectMonedas" :key="item.id" :label="item.nombre" :value="item.id" />
						</el-select>
						<span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
					</ValidationProvider>
					<p class="f-12 text-general f-500 pl-3 mt-4">Seleccionar Cedis</p>
					<el-select v-model="id_cedis" size="small" placeholder="seleccionar" class="w-100" @change="addCedis">
						<el-option v-for="item in selectCedis" :key="item.id" :label="item.nombre" :value="item.id"
							:disabled="item.disabled" />
					</el-select>
					<div v-for="(c, key) in cedisAgregados" :key="key"
						class="row mx-0 bg-light-f5 border align-items-center pl-2 mt-2 br-5 f-14" style="height:32px;">
						{{ c.nombre }} <i class="icon-cancel ml-auto mr-2 text-general f-16 cr-pointer"
							@click="removerCedis(c.id)" />
					</div>
				</div>
			</div>
		</ValidationObserver>
	</modal>
</template>

<script>
import Pagos from "~/services/pasarela-tesoreria/mercado-pago"
export default {
	data() {
		return {
			id_cedis: '',
			model: {
				nombre: '',
				descripcion: '',
				publickKey: '',
				accessToken: '',
				idm_moneda: '',
			},
			cedisAgregados: []
		}
	},
	computed: {
		selectMonedas() {
			return this.monedas.filter(o => o.sigla !== 'EUR')
		},
		selectCedis() {
			return this.cedis_calculo.filter(o => o.id != 0)
		}
	},
	methods: {
		toggle() {
			this.model = {
				nombre: '',
				descripcion: '',
				publickKey: '',
				accessToken: '',
				idm_moneda: '',
			},
			this.$refs.modalAgregarCuenta.toggle();
		},
		addCedis(id) {
			let c = this.selectCedis.find(o => o.id === id)
			c.disabled = true
			this.cedisAgregados.push(c)
			this.id_cedis = null
		},
		removerCedis(id) {
			this.selectCedis.find(o => o.id === id).disabled = false
			let c = this.selectCedis.find(o => o.id === id)
			this.cedisAgregados.splice(this.cedisAgregados.indexOf(c), 1)

		},
		async agregar() {
			try {
				const valid = await this.$refs.form.validate()
				if (!valid) return

				let cedis = this.cedisAgregados.map(element => element.id);
				this.model.idsCedis = cedis

				const { data } = await Pagos.postCuenta(this.model)
				this.notificacion('', data.mensaje, 'success')
				this.$emit('update', data.data)
				this.$refs.modalAgregarCuenta.toggle();

			} catch (e) {
				this.error_catch(e)
			}
		}
	}
}
</script>
